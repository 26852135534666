<template>
  <div class="alter-wall-38">
    <Loader :loading="showLoader" />
    <v-toolbar color="#222222" flat dense class="mb-2" style="cursor: pointer">
      <v-icon color="#077a2d" class="ml-2" size="40" @click="goBack">
        mdi-arrow-left-bold-circle
      </v-icon>
      <v-toolbar-title style="font-family: 'Material Design Icons'; font-size: 25px !important">
        {{ "Alter Wall 38" }}
      </v-toolbar-title>
    </v-toolbar>
    <v-container>
      <div>
        <!-- Text and Image Side by Side -->
        <v-card class="alter-wall-38-card">
          <v-card-title class="card-title">Work-Life-Quality auf allen Etagen</v-card-title>
          <v-card-text class="card-text">
            <v-row>
              <v-col cols="12" md="6">
                <div class="text-content">
                  <p>Der Alte Wall No. 38 bietet Büroflächen, die den Montagmorgen zum Highlight machen.</p>
                  <p>Um in der Arbeitswelt von morgen wettbewerbsfähig zu bleiben, müssen sich Unternehmen auch
                    räumlich entsprechend aufstellen und ihren Mitarbeitern ein Umfeld zur bestmöglichen Entfaltung
                    bieten. Der Alte Wall No. 38 ermöglicht dies auf einzigartige Weise, eingebunden in den Lifestyle
                    des gesamten Boulevards und in einer Lage, die keine Wünsche offenlässt. Auf acht Büroetagen bieten
                    sich ca. 9.000 qm flexible, top ausgestattete Büroflächen, die für Open-Space, Zellenbüros wie auch
                    Co-Working gleichermaßen geeignet sind – gekrönt von teilweise umlaufenden Terrassen mit Blick auf
                    den Alsterfleet und das Rathaus.
                  </p>
                  <p>Grundstück: ca. 1.500 m²</p>
                  <p>Gesamtfläche: ca. 11.100 m²</p>
                  <p>Nutzung: ca. 9.800 m² Büro, ca. 1.300 m² Gastronomie</p>
                  <p>Fertigstellung: Q2/Q3 2025</p>
                  <p>Architekten: Kunst und Herbert</p>
                </div>
              </v-col>
              <v-col cols="12" md="6" class="position-relative">
                <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/05/AWH_IV_CA_Office_004_1.jpg"
                  class="fact-image" alt="Fact Image" />
                <div class="image-overlay-text">Ideen neuen Raum geben</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!-- Stacking Plan Card -->
        <v-card class="stacking-plan-card">
          <v-card-title class="card-title">Stapelplan</v-card-title>
          <v-card-text class="card-text">
            <p>Endecken Sie top ausgestattete Büroflächen. Auf acht Etagen sind Open-Space, Zellenbüros als auch
              Co-Working
              gleichermaßen möglich.</p>
            <v-img
              src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/05/230213-Alter-Wall-38-Stacking-Plan.svg"
              class="fact-image" style="margin-top: 1.5rem"></v-img>
          </v-card-text>
        </v-card>

        <!-- First Class Location Card -->
        <v-card class="first-class-location-card">
          <v-card-title class="card-title">Eine Lage erster Klasse</v-card-title>
          <v-card-text class="card-text">
            <v-row>
              <v-col cols="12" md="6">
                <p>
                  Als <v-chip class="highlight-chip">Scharnier</v-chip> zwischen <v-chip
                    class="highlight-chip">westlicher</v-chip> und <v-chip class="highlight-chip">östlicher
                    Innenstadt</v-chip> könnte der <v-chip class="highlight-chip">Alte Wall</v-chip> nicht zentraler
                  liegen. <v-chip class="highlight-chip">Bus</v-chip> und <v-chip
                    class="highlight-chip">Bahnen</v-chip>, <v-chip class="highlight-chip">Kultur</v-chip> und <v-chip
                    class="highlight-chip">Kulinarik</v-chip>, <v-chip
                    class="highlight-chip">Sehenswürdigkeiten</v-chip> sowie <v-chip class="highlight-chip">Szene- und
                    Shopping-Spots</v-chip> sind fußläufig innerhalb kürzester Zeit erreichbar. Zudem ist der Alte Wall
                  als <v-chip class="highlight-chip">Flanierboulevard</v-chip> die attraktivste Verbindung vom <v-chip
                    class="highlight-chip">Rathausmarkt</v-chip> zur <v-chip class="highlight-chip">HafenCity</v-chip>.
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <img
                  src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/07/Alter-Wall-mit-Shop-by-Aylin-Koenig-Nutzungsrechte-Copyright-Art-Invest-Real-Estate.jpg"
                  class="fact-image" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!-- Sustainability Card -->
        <v-card class="sustainability-card">
          <v-card-title class="card-title">Einfach nachhaltig überall hin</v-card-title>
          <v-card-text class="card-text">
            <v-row>
              <v-col cols="12" md="6">
                <div class="text-content">
                  <p>
                    Wie schön, praktisch und nachhaltig, dass der Alte Wall so unkompliziert zu erreichen ist – auch
                    ohne
                    eigenen Wagen. Im Umfeld ist immer ein E-Roller oder ein Share-Fahrzeug zu finden, mit dem das
                    Parken auf
                    öffentlichen Straßen kostenlos ist. Das HW-Switch-Angebot ermöglicht das einfache Nutzen
                    verschiedener
                    Verkehrsmittel: Ob Bus, Bahn, Ridesharing mit MOIA, Taxi, Car-Sharing, StadtRAD oder E-Roller – die
                    Mobilitätsapp
                    sagt, wie man am schnellsten sein Ziel erreichen kann.
                  </p>
                </div>
                <img style="margin-top: 1rem"
                  src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/05/iStock-515892364-web.jpg"
                  class="fact-image" alt="Bicycle Parking" />
                <p class="image-description">Der Alte Wall 38 bietet zahlreiche Fahrradstellplätze im Erd- und
                  Untergeschoss.</p>
              </v-col>
              <v-col cols="12" md="6">
                <div class="qr-code-container">
                  <img :src="qrCodeImage" class="qr-code-image" alt="QR Code Image" />
                  <p class="qr-code-text">
                    Das Hamburger Veloroutennetz<br><br>
                    (QR-Code) besteht aus zwölf sternförmigen Routen, die vom Rathausmarkt starten und die Innenstadt
                    mit den
                    äußeren Stadtteilen verbinden. Das Netz wird weiter ausgebaut, damit Pendler bequem per Rad zur
                    Arbeit gelangen können.
                  </p>
                </div>
                <img style="margin-top: 1rem"
                  src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/07/iStock-1336148368.jpg"
                  class="fact-image" alt="E-Bike Stations" />
                <p class="image-description">Neben E-Ladestationen für die E-Bikes mit Strom, der aus der
                  Photovoltaikanlage
                  auf dem Dach kommt, gibt es auch Duschen und Umkleiden für einen frischen Start in den Büroalltag.
                </p>
              </v-col>
            </v-row>
            <!-- <v-row>
      <v-col cols="12" md="6" class="image-text-container">
        <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/05/iStock-515892364-web.jpg" class="fact-image" alt="Bicycle Parking" />
        <p class="image-description">Der Alte Wall 38 bietet zahlreiche Fahrradstellplätze im Erd- und Untergeschoss.</p>
      </v-col>
      <v-col cols="12" md="6" class="image-text-container">
        <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/07/iStock-1336148368.jpg" class="fact-image" alt="E-Bike Stations" />
        <p class="image-description">Neben E-Ladestationen für die E-Bikes mit Strom, der aus der Photovoltaikanlage auf dem Dach kommt, gibt es auch Duschen und Umkleiden für einen frischen Start in den Büroalltag.</p>
      </v-col>
    </v-row> -->
          </v-card-text>
        </v-card>
        <!-- Hamburgische Bürgerschaft and Gastronomie -->
        <v-card class="buerger-card">
          <v-card-title class="card-title">und Gastronomie</v-card-title>
          <v-card-text class="card-text">
            <p>Die Hamburgische Bürgerschaft mit ihren Fraktionen und der Landtagsverwaltung (Bürgerschaftskanzlei) wird
              in Zukunft
              am Alten Wall 38 unter einem Dach Büros haben. Ein idealer Standort für Politik, denn das neue „Haus der
              Bürgerschaft“
              ist nur wenige Schritte vom Hamburger Rathaus entfernt. Mitte 2025, wenn die modernen Flächen am Alten
              Wall 38
              bezugsfertig sind, zieht ins Erdgeschoss auch das Brauhaus Joh. Albrecht wieder ein, das bereits seit 30
              Jahren im
              Gebäude ansässig war.
            </p>
            <v-row style="margin-top: 0.1rem">
              <v-col cols="12" md="6" class="image-text-container">
                <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/07/WP21_1510VfZZ99.jpg"
                  class="fact-image" />
                <v-chip style="border-radius: 0px; margin-top: 0.2rem" size="large" class="image-text">Stimmungsbild<br>
                  Haus der Bürgerschaften (Credits: Hamburgische Bürgerschaft/M.Zapf)
                </v-chip>
                <v-btn href="https://www.hamburgische-buergerschaft.de/" target="_blank"
                  style="background-color: #077a2d; margin-top: 0.6rem">Zur Website
                </v-btn>
              </v-col>
              <v-col cols="12" md="6" class="image-text-container">
                <img
                  src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/07/roberta-keiko-kitahara-santana-RfL3l-I1zhc-unsplash.jpg"
                  class="fact-image" />
                <v-chip style="border-radius: 0px; margin-top: 0.2rem" size="large" class="image-text">Stimmungsbild<br>
                  Brauhaus Joh. Albrecht
                </v-chip>
                <v-btn href="https://www.brauhaus-joh-albrecht.de/" target="_blank"
                  style="background-color: #077a2d; margin-left: 0.6rem">Zur Website</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/08/VISU-NEU.jpg"
                  class="fact-image small-image" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="image-text-container">
                <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/05/AWH_IV_BA_Office_005.jpg"
                  class="fact-image" />
                <v-chip style="border-radius: 0px; margin-top: 0.2rem" size="large" class="image-text">7.
                  Obergeschoss<br>
                  Arbeiten mit Aussicht
                </v-chip>
              </v-col>
              <v-col cols="12" md="6" class="image-text-container">
                <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/05/AWH_Terrace_02_BG_004.jpg"
                  class="fact-image" />
                <v-chip style="border-radius: 0px; margin-top: 0.2rem" size="large" class="image-text">7.
                  Obergeschoss<br>
                  Staffelgeschoss mit umlaufender Terrasse
                </v-chip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!-- Under Construction Card -->
        <v-card class="under-construction-card">
          <v-card-title class="card-title">Bauarbeiten im Gange</v-card-title>
          <v-card-text class="card-text">
            <v-row>
              <v-col cols="12" md="6" class="image-text-container">
                <img
                  src="https://www.alter-wall-hamburg.de/wp-content/uploads/2024/04/AlterWall_Z4A4284-min-1920x1281.jpg"
                  class="fact-image" />
                <p>Während der Alte Wall 38 wieder in die Höhe wächst, finden am Alten Wall 40 die Vorbereitungen für
                  die Gründungsarbeiten statt.</p>
              </v-col>
              <v-col cols="12" md="6" class="image-text-container">
                <img
                  src="https://www.alter-wall-hamburg.de/wp-content/uploads/2024/04/ArtInvest_Z4A2850-min-1920x1281.jpg"
                  class="fact-image" />
                <p>Planung und Bau. Passt hier alles zusammen?</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="image-text-container">
                <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2024/06/ArtInvest_-145-min-1920x1281.jpg"
                  class="fact-image" />
                <p>Optischer Gegensatz: Historisches Gebäudeensemble trifft auf Großbaustelle</p>
              </v-col>
              <v-col cols="12" md="6" class="image-text-container">
                <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2024/06/ArtInvest_-120-min-1920x1281.jpg"
                  class="fact-image" />
                <p>Blick aus dem Innenhof nach oben. Das Gebäude ist bisher bis zum 4. OG fertiggestellt.</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="image-text-container">
                <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2024/06/ArtInvest_-82-min-1920x1281.jpg"
                  class="fact-image" />
                <p>Blick auf die Herstellung des Treppenhauskerns in der vierten Etage.</p>
              </v-col>
              <v-col cols="12" md="6" class="image-text-container">
                <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2024/06/ArtInvest_-58-min-1920x1281.jpg"
                  class="fact-image" />
                <p>Krahn-Gerüst-Geometrie</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import Loader from '@/components/Loader.vue';

export default {
  name: 'AlterWall38Component',
  components: {
    Loader,
  },
  setup() {
    const showLoader = ref(true);
    const router = useRouter();

    const goBack = () => {
      router.go(-1);
    };
    onMounted(() => {
      setTimeout(() => {
        showLoader.value = false;
      }, 800);
    });

    return {
      qrCodeImage: require('@/assets/Branding/Artinvest/hamburgQR.png'),
      showLoader,
      goBack,
    };
  },
};
</script>

<style scoped>
/* .alter-wall-38 {
  overflow: hidden;
  padding: 10px;
} */

/* Styling for cards */
.alter-wall-38-card,
.stacking-plan-card,
.first-class-location-card,
.sustainability-card,
.buerger-card,
.under-construction-card {
  width: 100%;
  margin: 20px auto;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background-color: white;
}

.card-title {
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

.card-text {
  padding: 20px;
  font-size: 1em;
  color: #333;
}

.text-content p {
  margin-bottom: 15px;
}

.fact-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.image-overlay-text {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .card-title {
    font-size: 1.5em;
  }

  .fact-image {
    height: auto;
    width: 100%;
  }

  .text-content p {
    margin-bottom: 10px;
  }

  .image-overlay-text {
    font-size: 1em;
  }
}
</style>
