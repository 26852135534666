<template>
  <div class="projects-list">
    <Loader :loading="showLoader" />
    <v-toolbar color="#222222" flat dense class="mb-2" style="cursor: pointer">
      <v-icon color="#077a2d" class="ml-2" size="40" @click="router.go(-1)">mdi-arrow-left-bold-circle</v-icon>
      <v-toolbar-title style="font-family: 'Material Design Icons'; font-size: 25px !important">
        {{ "Art-Invest" + "\t" + "Projekte" }}
      </v-toolbar-title>
    </v-toolbar>
    <transition name="fade" mode="out-in">
      <div>
        <v-container class="px-10 py-5">
          <v-row align="center">
            <v-col cols="12" md="8" lg="9">
              <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="Projekt nach Namen oder Ort suchen"
                single-line hide-details variant="solo-inverted" density="compact" class="elegant-search"
                full-width></v-text-field>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <v-menu :location="'start'" max-height="500px">
                <template v-slot:activator="{ props }">
                  <v-btn small color="green" dark v-bind="props" class="filter-button">
                    <v-icon left size="small">mdi-filter</v-icon>
                    Suche nach Stadt
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="filterByLocation('All')">
                    <v-list-item-title>Alle</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-for="location in uniqueLocations" :key="location" @click="filterByLocation(location)">
                    <v-list-item-title>{{ location }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row class="justify-center">
            <transition-group name="fade" tag="div" class="d-flex flex-wrap">
              <v-col :cols="filteredProjects.length === 1 ? 12 : 6" :md="filteredProjects.length === 1 ? 8 : 4"
                :lg="filteredProjects.length === 1 ? 6 : 3" v-for="project in filteredProjects" :key="project.id"
                class="project-card">
                <v-card elevation="4" class="card-hover full-height">
                  <v-img height="200px" :src="project.featured_image" cover></v-img>
                  <v-card-title class="title">{{ project.title.rendered }}</v-card-title>
                  <v-card-subtitle class="subtitle">{{ project.place }}</v-card-subtitle>
                  <v-card-text class="description">{{ project.subtitle }}</v-card-text>
                  <v-card-actions>
                    <v-btn variant="outlined" class="learn-more-button" @click="openProjectLink(project.guid.rendered)">
                      Mehr lesen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </transition-group>
          </v-row>
        </v-container>
      </div>
    </transition>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import Loader from "@/components/Loader.vue";
import { useRouter } from "vue-router";
import { getArtinvestProjects } from "@/services/ArtinvestFixedData/ArtinvestProjects.js";

export default {
  name: "ProjectsList",
  setup() {
    const router = useRouter();
    const showLoader = ref(true);
    const projectsList = ref([]);
    const search = ref("");
    const locations = ref([]);
    const currentFilter = ref("All");

    onMounted(() => {
      let projects = getArtinvestProjects();
      let places = new Set(projects.map((project) => project.place.trim()).filter((place) => place));
      locations.value = places;
      projectsList.value = projects;
    });

    const uniqueLocations = computed(() => locations.value);

    const filteredProjects = computed(() => {
      if (!search.value && currentFilter.value === "All") return projectsList.value;

      return projectsList.value.filter((project) => {
        const titleMatch = project.title.rendered.toLowerCase().includes(search.value.toLowerCase());
        const placeMatch = project.place.toLowerCase().includes(search.value.toLowerCase());
        const locationFilterMatch = currentFilter.value === "All" || project.place === currentFilter.value;
        return (titleMatch || placeMatch) && locationFilterMatch;
      });
    });

    const filterByLocation = (location) => {
      currentFilter.value = location;
      search.value = location === "All" ? "" : location;
    };

    const openProjectLink = (url) => {
      window.open(url, "_blank");
    };

    return {
      router,
      showLoader,
      projectsList,
      search,
      filteredProjects,
      uniqueLocations,
      filterByLocation,
      openProjectLink,
    };
  },
};
</script>

<style scoped>
.filter-button {
  margin-right: 16px;
  min-width: 90px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.elegant-search .v-text-field__slot {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
}

.elegant-search input {
  font-size: 16px;
}

.card-hover {
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-hover:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.learn-more-button {
  background-color: #077a2d;
  color: white;
  transition: background-color 0.3s ease;
}

.learn-more-button:hover {
  background-color: #077a2d;
}

.project-card {
  max-width: 100%;
}

.title {
  font-size: 1rem;
  font-weight: bold;
}

.subtitle {
  font-size: 0.8rem;
  color: #077a2d;
  white-space: normal;
  line-height: 1.2em;
  max-height: 2.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.description {
  font-size: 0.9rem;
  flex-grow: 1;
}
</style>
