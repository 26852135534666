<template>
  <div class="projects-list">
    <Loader :loading="showLoader" />
    <v-toolbar color="#222222" flat dense class="mb-2">
      <v-icon color="#077a2d" class="ml-2" size="40" @click="router.go(-1)">
        mdi-arrow-left-bold-circle
      </v-icon>
      <v-toolbar-title style="font-family: 'Material Design Icons'; font-size: 25px !important">
        Art-Invest Filme
      </v-toolbar-title>
    </v-toolbar>
    <transition name="fade" mode="out-in">
      <v-container class="video-grid">
        <v-row justify="center">
          <v-col cols="12" sm="6" md="4" lg="3" xl="3" v-for="video in videos" :key="video.id" class="video-container"
            @click="openPlyr(video)">
            <div class="video-thumbnail">
              <img :src="video.thumbnail" alt="Video Thumbnail" class="thumbnail-image" />
              <div class="overlay">
                <div class="play-icon">&#9658;</div>
                <div class="video-title">{{ video.title }}</div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </transition>
    <div v-if="dialog" class="modal">
      <vue3-video-play v-bind="selectedVideo.options" @play="onPlay" @pause="onPause" @ended="onEnded" />
      <button class="close-button" @click="closeModal">&#10005;</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from "vue";
import Loader from "@/components/Loader.vue";
import { useRouter } from "vue-router";
import videos from "../services/ArtinvestFixedData/project_videos.js"; // Importing the videos array


export default {
  name: "ProjectFilmsList",
  components: {
    Loader,
  },
  setup() {
    const dialog = ref(false);
    const selectedVideo = ref({});
    const router = useRouter();
    const videosData = ref(videos);

    function openPlyr(video) {
      let clickedVideo = JSON.parse(JSON.stringify(video));
      selectedVideo.value = clickedVideo;
      dialog.value = true;
    }

    function closeModal() {
      dialog.value = false;
    }

    function onPlay() {
      console.log("Video is playing");
    }

    function onPause() {
      console.log("Video is paused");
    }

    function onEnded() {
      console.log("Video has ended");
    }

    return {
      dialog,
      openPlyr,
      closeModal,
      selectedVideo,
      videos: videosData,
      onPlay,
      onPause,
      onEnded,
      router,
    };
  },
};
</script>

<style scoped>
.video-grid {
  max-width: 960px;
  /* Center the container */
  margin: 0 auto;
  padding-top: 20px;
}

.video-container {
  margin: 10px;
  position: relative;
  cursor: pointer;
}

.video-thumbnail {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.thumbnail-image {
  width: 100%;
  display: block;
  transition: transform 0.3s ease;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.play-icon {
  font-size: 48px;
  color: white;
  transition: transform 0.3s ease;
}

.video-title {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 18px;
  padding: 10px 0;
}

.video-thumbnail:hover .thumbnail-image {
  transform: scale(1.05);
}

.video-thumbnail:hover .overlay {
  background: rgba(0, 0, 0, 0.8);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 860px;
  background: black;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  border: none;
  background: none;
  color: #aaa;
  font-size: 24px;
  line-height: 1;
}

.close-button:hover {
  color: #777;
}
</style>
