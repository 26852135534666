<template>
  <div>
    <Loader :loading="showLoader" />
    <v-toolbar color="#222222" flat dense class="mb-4">
      <v-icon style="margin-left: 10px" size="40" @click="goBack">mdi-arrow-left-bold-circle</v-icon>
      <v-toolbar-title style="font-family: 'Material Design Icons'; font-size: 25px !important">
        {{ "R e s t a u r a n t s" }}
      </v-toolbar-title>
      <!-- <div v-if="!isGPSEnabled" class="text-center location-button">
        <v-btn append-icon="mdi-account-circle" prepend-icon="mdi-check-circle" variant="tonal" :ripple="true"
          rounded="lg" size="large" @click="requestLocation">
          <template v-slot:prepend>
            <v-icon>mdi-account</v-icon>
          </template>
<span v-if="!isMobile" style="color:white">{{ "GPS aktivieren" }}</span>
<template v-slot:append>
            <v-icon>mdi-map-marker-radius</v-icon>
          </template>
</v-btn>
</div> -->
      <img :src="logo" class="toolbar-icon" />
    </v-toolbar>

    <!-- Search Bar -->
    <div class="search-bar" style="margin-top: 1.5rem">
      <input v-model="searchQuery" placeholder="Suchen Sie nach Ihrem Lieblingsrestaurant" />
      <div class="search-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="47" viewBox="0 0 24 24" fill="none" stroke="#ffffff"
          stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search">
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
      </div>
    </div>

    <div class="cards-container">
      <RestaurantCard v-for="(card, index) in filteredCards" :key="index" :card="card" :index="index"
        :getCardImage="getCardImage" :viewPdf="viewPdf" :userPosition="userPosition" />
    </div>

    <!-- Custom PDF/Iframe Viewer Modal -->
    <div v-if="pdfDialog" class="modal-overlay" @click.self="pdfDialog = false">
      <div class="modal-content">
        <button class="modal-close" @click="pdfDialog = false">✖</button>
        <div class="pdf-viewer" v-if="isPdf">
          <PDF v-if="pdfSrc" :src="pdfSrc" @onComplete="onPdfComplete" class="pdf-viewer-content" />
        </div>
        <div class="iframe-container" v-else>
          <iframe :src="pdfSrc" class="iframe-content"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, onUnmounted, watch } from "vue";
import { useRouter } from "vue-router";
import PDF from "pdf-vue3";
import Loader from "@/components/Loader.vue";
import RestaurantCard from "@/components/ChildComponents/RestaurantCard.vue";
import { getToolBarLogo } from "@/assets/Branding/branding.js";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

export default defineComponent({
  name: "RestaurantsView",
  components: {
    Loader,
    PDF,
    RestaurantCard
  },
  setup() {
    const showLoader = ref(false);
    const logo = ref("");
    const router = useRouter();
    const searchQuery = ref("");
    const pdfDialog = ref(false);
    const pdfSrc = ref("");
    const pdfLoading = ref(true);
    const isPdf = ref(true);
    const userPosition = ref(null);
    const isMobile = ref(window.innerWidth < 600);
    const isGPSEnabled = ref(sessionStorage.getItem('isGPSEnabled') === 'true');

    const goBack = () => {
      router.go(-1);
    };

    const cards = ref([
      { title: "Treffen Sie Ihre Wahl bei", text: "Cotidiano", link: "https://www.cotidiano.de/_files/ugd/fa297d_4da52bd3a9254fd5976d356ba0444018.pdf", linkText: "Speisekarte", extraText: "Ein gemütlicher", extraHighlight: "ort", discount: "um Ihr Essen zu ", extraDiscount: "genießen", lat: "53.5506242", lng: "9.9907894" },
      { title: "Finden Sie das Beste bei", text: "Wallter's Bistro & Kontor", link: "https://www.wallters.de/_files/ugd/cc5efd_797c4909925247259038c0c39401cee1.pdf", linkText: "Speisekarte", extraText: "Erleben Sie", extraHighlight: "beste", discount: "kulinarische", extraDiscount: "Genüsse", lat: "53.5509312", lng: "9.9910422" },
      { title: "Genießen Sie Ihr Essen im", text: "Goldbach Restaurant", link: "https://editoruploads.s3.juneapp.com/danielwischer/fef540cfefa0d2ecea5c687b3bd91a12.pdf", linkText: "Speisekarte", extraText: "Feines", extraHighlight: "Essen", discount: "vom", extraDiscount: "Feinsten", lat: "53.5501354", lng: "9.9905755" },
      { title: "Schmecken Sie den Unterschied bei", text: "Restaurant Parlament", link: "https://www.parlament-hamburg.de/unsere-karte/", linkText: "Speisekarte", extraText: "Einzigartige", extraHighlight: "Aromen", discount: "aerwarten", extraDiscount: "Sie", lat: "", lng: "" },
      { title: "Wählen Sie Ihren Genuss bei", text: "Ristorante Dolcetto", link: "http://www.dolcetto-hamburg.de/index.php/speisekarte/speisekarte", linkText: "Speisekarte", extraText: "Genießen", extraHighlight: "Sie", discount: "jeden", extraDiscount: "Bissen", lat: "", lng: "" },
      { title: "Genießen Sie den Geschmack bei", text: "Restaurant Rialto", link: "https://restaurantrialto.de/speisen_und_getraenke/speisekarten/abendkarte/", linkText: "Speisekarte", extraText: "Probieren", extraHighlight: "Sie erstaunliche", discount: "Aromen die Sie", extraDiscount: "erwarten", lat: "", lng: "" },
      { title: "Wählen Sie Ihre Favoriten unter", text: "Restaurant Pfeffersack", link: "https://pfeffersack.restaurant/", linkText: "Speisekarte", extraText: "Entdecken Sie", extraHighlight: "noch heute", discount: "Ihre", extraDiscount: "Favoriten", lat: "", lng: "" },
      { title: "Genießen Sie den Moment im", text: "Osteria Enoteca - I Vigneri", link: "https://www.ivigneri.de/wp-content/uploads/2024/04/Speisekarte-03-2024.pdf", linkText: "Speisekarte", extraText: "Genießen Sie", extraHighlight: "jeden", discount: "Moment mit", extraDiscount: "Gerichten", lat: "", lng: "" },
      { title: "Entdecken Sie die Freude an", text: "Spaccaforno – Börsenbrücke", link: "https://spaccaforno.de/wp-content/uploads/Spaccoforno_Flyer_Boersenbruecke_16-02-2024_korr-1.pdf", linkText: "Speisekarte", extraText: "Leckere", extraHighlight: "Leckereien", discount: "für", extraDiscount: "alle", lat: "", lng: "" },
      { title: "Verwöhnen Sie sich im", text: "Le Plat du Jour", link: "https://www.le-plat-du-jour.de/speisekarte/", linkText: "Speisekarte", extraText: "Ein", extraHighlight: "Gourmet-Erlebnis ", discount: "erwartet", extraDiscount: "Sie", lat: "", lng: "" },
      { title: "Erleben Sie das Beste bei", text: "Atelier F", link: "https://atelierf.de/wp-content/uploads/2024/04/AtelierF_Menue_24-04.pdf", linkText: "Speisekarte", extraText: "Erstklassiges", extraHighlight: "Essen", discount: "zu Ihrer", extraDiscount: "Verfügung", lat: "", lng: "" },
      { title: "Genießen Sie jeden Bissen im", text: "Saliba Alsterarkaden", link: "https://saliba.de/speisekarte/", linkText: "Speisekarte", extraText: "Unvergessliche", extraHighlight: "Aromen", discount: "für Ihren", extraDiscount: "Gaumen", lat: "", lng: "" }
    ]);

    const getCardImage = (index) => {
      const images = [
        require('@/assets/Branding/Artinvest/RestaurantViewPictures/restaurant_1.png'),
        require('@/assets/Branding/Artinvest/RestaurantViewPictures/restaurant_2.png'),
        require('@/assets/Branding/Artinvest/RestaurantViewPictures/restaurant_3.png'),
        require('@/assets/Branding/Artinvest/RestaurantViewPictures/restaurant_4.png'),
        require('@/assets/Branding/Artinvest/RestaurantViewPictures/restaurant_5.png'),
        require('@/assets/Branding/Artinvest/RestaurantViewPictures/restaurant_6.png'),
        require('@/assets/Branding/Artinvest/RestaurantViewPictures/restaurant_7.png'),
        require('@/assets/Branding/Artinvest/RestaurantViewPictures/restaurant_8.png'),
        require('@/assets/Branding/Artinvest/RestaurantViewPictures/restaurant_9.png'),
        require('@/assets/Branding/Artinvest/RestaurantViewPictures/restaurant_10.png'),
        require('@/assets/Branding/Artinvest/RestaurantViewPictures/restaurant_11.png'),
        require('@/assets/Branding/Artinvest/RestaurantViewPictures/restaurant_12.png'),
      ];
      return images[index];
    };

    const filteredCards = computed(() => {
      return cards.value.filter(card =>
        card.title.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        card.text.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const viewPdf = (link) => {
      isPdf.value = link.endsWith(".pdf");
      pdfSrc.value = link;
      pdfDialog.value = true;
    };

    const onPdfComplete = () => {
      showLoader.value = false;
    };

    const requestLocation = async () => {
      const { value: accept } = await Swal.fire({
        title: 'Standort Erforderlich',
        text: 'Wir benötigen Ihren Standort, um die Entfernung zwischen Ihnen und den Restaurants anzuzeigen. Wenn Sie nicht zustimmen, müssen Sie den Standort manuell aktivieren.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: "green",
        confirmButtonText: 'OK',
        cancelButtonText: 'Abbrechen',
      });

      if (accept) {
        try {
          await loadGoogleMapsAPI();
          const position = await requestLocationPermission();
          userPosition.value = position;
          isGPSEnabled.value = true;
          sessionStorage.setItem('isGPSEnabled', 'true');
          sessionStorage.setItem('userPosition', JSON.stringify(position));
          Swal.fire('Erfolg', 'Standort erfolgreich abgerufen!', 'success');
        } catch (error) {
          Swal.fire('Fehler', error.message, 'error');
        }
      }
    };

    const loadGoogleMapsAPI = () => {
      let apiKey = "AIzaSyBgm4lMPo33kPSW37a7gtTyCU1Hvy9le7Q";
      return new Promise((resolve, reject) => {
        if (typeof google !== 'undefined') {
          resolve();
          return;
        }

        window.initMap = () => {
          resolve();
        };

        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`;
        script.async = true;
        script.defer = true;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    };

    const requestLocationPermission = () => {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              resolve(pos);
            },
            (error) => {
              switch (error.code) {
                case error.PERMISSION_DENIED:
                  reject(new Error("Benutzer hat die Anfrage zur Geolokalisierung abgelehnt."));
                  break;
                case error.POSITION_UNAVAILABLE:
                  reject(new Error("Standortinformationen sind nicht verfügbar."));
                  break;
                case error.TIMEOUT:
                  reject(new Error("Die Anfrage zur Standortbestimmung ist abgelaufen."));
                  break;
                default:
                  reject(new Error("Ein unbekannter Fehler ist aufgetreten."));
                  break;
              }
            }
          );
        } else {
          reject(new Error("Geolokalisierung wird von diesem Browser nicht unterstützt."));
        }
      });
    };

    const handleResize = () => {
      isMobile.value = window.innerWidth < 600;
    };

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
    });

    onMounted(async () => {
      window.addEventListener('resize', handleResize);
      showLoader.value = true;
      logo.value = await getToolBarLogo();

      if (isGPSEnabled.value && sessionStorage.getItem('userPosition')) {
        userPosition.value = JSON.parse(sessionStorage.getItem('userPosition'));
      }

      setInterval(async () => {
        if (isGPSEnabled.value) {
          try {
            const position = await requestLocationPermission();
            userPosition.value = position;
            sessionStorage.setItem('userPosition', JSON.stringify(position));
          } catch (error) {
            console.error('Failed to update position:', error);
          }
        }
      }, 30000);

      setTimeout(() => {
        showLoader.value = false;
      }, 500);
    });

    return {
      showLoader,
      logo,
      goBack,
      searchQuery,
      pdfDialog,
      pdfSrc,
      pdfLoading,
      cards,
      getCardImage,
      filteredCards,
      viewPdf,
      onPdfComplete,
      requestLocation,
      userPosition,
      isMobile,
      isGPSEnabled
    };
  }
});
</script>

<style scoped>
/* Add your scoped styles here */
.toolbar-icon {
  height: 30px;
  align-self: center;
  margin-right: 20px;
}

.location-button {
  margin-left: auto;
  margin-right: 10px;
  color: #45a049;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-content: center;
  gap: 16px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  /* Set a maximum width for responsiveness */
  margin: 0 auto 20px;
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.search-bar input {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: var(--border-radius) 0 0 var(--border-radius);
  font-family: 'Orbitron', sans-serif;
  font-weight: 600;
  color: #657789;
}

.search-bar input::placeholder {
  color: #657789;
}

.search-bar input:focus {
  outline: none;
}

.search-icon {
  background-color: #4CAF50;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  cursor: pointer;
}

.search-icon:hover {
  background-color: #45a049;
}

.search-icon svg {
  stroke: #ffffff;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 900px;
  height: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.modal-close {
  position: absolute;
  top: -15px;
  /* Adjusted to be above the modal */
  right: -15px;
  /* Adjusted to be aligned with the modal */
  background: #ffffff;
  border: none;
  font-size: 24px;
  cursor: pointer;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-close:hover {
  background: #f1f1f1;
}

.pdf-viewer {
  flex: 1;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-viewer-content {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.iframe-container {
  flex: 1;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.iframe-content {
  width: 100%;
  height: 100%;
  border: none;
}

/* Map Button styles */
.custom-map-control-button {
  background-color: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 0 0.5em;
  font: 400 18px Roboto, Arial, sans-serif;
  overflow: hidden;
  height: 40px;
  cursor: pointer;
}

.custom-map-control-button:hover {
  background: rgb(235, 235, 235);
}
</style>
